<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left" v-if="terminal=='pc'">
      <el-date-picker v-model="timerange" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" @change="page=1" size="mini" :picker-options="pickerOptions"></el-date-picker>
      <el-button size="mini" @click="getlist" class="margin-left">统计</el-button>
      <el-button class="margin-left" type="success" size="mini" @click="outList">导出</el-button>
    </el-row>
    <div v-else>
      <el-date-picker v-model="timerange" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" @change="page=1" size="mini" :picker-options="pickerOptions"></el-date-picker>
      <el-row :gutter="10">
        <el-col :span=12>
          <el-button style="width:100%" size="mini" @click="getlist">统计</el-button>
        </el-col>
        <el-col :span="12">
          <el-button style="width:100%" type="success" size="mini" @click="outList">导出</el-button>
        </el-col>
      </el-row>
    </div>
    <div v-for="(item,index) in list" :key="index">
      <p class="statistics-title">{{ item.title }}</p>
      <el-table :data="item.list" border show-summary>
        <el-table-column prop="company" label="客户/平台"></el-table-column>
        <el-table-column prop="workload" label="工作量" width="80"></el-table-column>
        <el-table-column prop="amount" label="金额" width="80"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      timerange: [this.getDate("start"), this.getDate()],
      list: [],
    };
  },
  computed: {
    pickerOptions() {
      let _this = this;
      return {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", [_this.getDate("today"), _this.getDate()]);
            },
          },
          {
            text: "本周",
            onClick(picker) {
              picker.$emit("pick", [_this.getDate("toweek"), _this.getDate()]);
            },
          },
          {
            text: "上月",
            onClick(picker) {
              picker.$emit("pick", [
                _this.getDate("premonth"),
                _this.getDate("premonthend"),
              ]);
            },
          },
          {
            text: "本月",
            onClick(picker) {
              picker.$emit("pick", [_this.getDate("start"), _this.getDate()]);
            },
          },
          {
            text: "本年",
            onClick(picker) {
              picker.$emit("pick", [
                _this.getDate("crntyear"),
                _this.getDate(),
              ]);
            },
          },
        ],
      };
    },
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/record/statistics",
        method: "post",
        data: {
          timerange: this.timerange,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.list = res.res;
        }
      });
    },
    outList() {
      this.$axios({
        url: this.HOST + "/record/outstatistics",
        method: "post",
        data: {
          timerange: this.timerange,
        },
        responseType: "blob",
      }).then((res) => {
        let blob = new Blob([res]);
        let fileName = "信息列表.csv";
        if ("download" in document.createElement("a")) {
          // 不是IE浏览器
          let url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(url); // 释放掉blob对象
        } else {
          // IE 10+
          window.navigator.msSaveBlob(blob, fileName);
        }
      });
    },
  },
  created: function () {},
};
</script>
<style>
.statistics-title {
  line-height: 40px;
  font-weight: bold;
}
</style>