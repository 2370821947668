<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left" v-if="terminal=='pc'">
      <el-col :span='6'>
        <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini"></el-input>
      </el-col>
      <el-date-picker v-model="timerange" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" style="width:250px;" class="margin-left" @change="page=1" size="mini" :picker-options="pickerOptions"></el-date-picker>
      <el-cascader v-model="crntType" :options="typeList" size="mini" style="width:300px" placeholder="请选择工作类型" class="margin-left" clearable :props="{ checkStrictly: true }"></el-cascader>
      <el-select v-model="isDetail" size="mini" class="margin-left">
        <el-option label="统计" :value="1">统计</el-option>
        <el-option label="详细" :value="2">详细</el-option>
      </el-select>
      <el-button class="margin-left" size="mini" @click="srchRecord">查找</el-button>
      <el-button type="success" size="mini" @click="addDiv=true">添加记录</el-button>
    </el-row>
    <div v-else>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini" @keyup.enter.native="srchRecord"></el-input>
        </el-col>
        <el-col :span="12">
          <el-cascader v-model="crntType" :options="typeList" size="mini" style="width:100%" placeholder="请选择工作类型" clearable :props="{ checkStrictly: true }"></el-cascader>
        </el-col>
      </el-row>
      <el-date-picker v-model="timerange" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" @change="page=1" size="mini" :picker-options="pickerOptions"></el-date-picker>
      <el-row :gutter="10">
        <el-col :span=12>
          <el-button style="width:100%" size="mini" @click="srchRecord">查找</el-button>
        </el-col>
        <el-col :span="12">
          <el-button style="width:100%" type="success" size="mini" @click="addDiv=true">添加</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" row-key="menuid" border>
      <el-table-column label="客户名称" fixed>
        <template slot-scope="scope">
          {{ scope.row.type==3?scope.row.platform:scope.row.company }}
        </template>
      </el-table-column>
      <el-table-column prop="type_text" label="工作内容"></el-table-column>
      <el-table-column label="工作量" width="70">
        <template slot-scope="scope">
          {{ scope.row.workload }}{{ scope.row.unit }}
        </template>
      </el-table-column>
      <el-table-column prop="time" label="日期"></el-table-column>
      <el-table-column label="操作" width='60'>
        <template slot-scope="scope">
          <el-button type='text' @click="del(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="terminal=='pc'" background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-pagination v-else background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize"  @current-change="handleCurrentChange" layout="total, prev, pager, next" :total="total"></el-pagination>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @closed="resetInfo()">
      <el-form :model="recordInfo" ref="recordInfo" :label-position="terminal=='pc'?'right':'top'" class="text-left">
        <el-form-item label="日期" :label-width="formLabelWidth" class="text-left">
          <el-date-picker v-model="recordInfo.time" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工单" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="recordInfo.taskid" placeholder="请选择" filterable default-first-option style="width:400px">
            <el-option v-for="(item,index) in taskList" :key="index" :label="item.company+'('+item.type+','+item.schedule+item.unit+')'" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作量" :label-width="formLabelWidth">
          <el-input v-model="recordInfo.workload" auto-complete="off" type="number">
            <template slot="append">{{ typeUnit }}</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      list: [], //管理员列表
      typeList: [],
      keyword: "",
      page: 1,
      total: 0,
      addDiv: false,
      recordInfo: {
        id: "",
        time: this.getDate(),
        taskid: "",
        workload: "",
      },
      taskList: [],
      tempInfo: {},
      timerange: [this.getDate("start"), this.getDate()],
      crntType: "",
      isDetail: 1
    };
  },
  computed: {
    typeUnit() {
      for (let item in this.taskList) {
        if (this.taskList[item].id == this.recordInfo.taskid)
          return this.taskList[item].unit;
      }
    },
    pickerOptions() {
      let _this = this;
      return {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", [_this.getDate("today"), _this.getDate()]);
            },
          },
          {
            text: "本周",
            onClick(picker) {
              picker.$emit("pick", [_this.getDate("toweek"), _this.getDate()]);
            },
          },
          {
            text: "上月",
            onClick(picker) {
              picker.$emit("pick", [
                _this.getDate("premonth"),
                _this.getDate("premonthend"),
              ]);
            },
          },
          {
            text: "本月",
            onClick(picker) {
              picker.$emit("pick", [_this.getDate("start"), _this.getDate()]);
            },
          },
          {
            text: "本年",
            onClick(picker) {
              picker.$emit("pick", [
                _this.getDate("crntyear"),
                _this.getDate(),
              ]);
            },
          },
        ],
      };
    },
  },
  methods: {
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    getInfo() {
      this.$axios({
        url: this.HOST + "/record/getTaskList",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.status == 1) {
          this.taskList = res.list;
          this.typeList = res.typelist;
        }
      });
    },
    getlist() {
      this.list = []
      this.$axios({
        url: this.HOST + "/record/getList",
        method: "post",
        data: {
          keyword: this.keyword,
          timerange: this.timerange,
          type: this.crntType,
          page: this.page,
          pagesize: this.pagesize,
          isDetail:this.isDetail
        },
      }).then((res) => {
        if (res.status == 1) {
          this.list = res.list.data;
          this.total = res.list.total;
        }
      });
    },
    //重置菜单编辑对话框内容
    resetInfo() {
      for (let item in this.tempInfo) {
        this.recordInfo[item] = this.tempInfo[item];
      }
    },
    del(scope) {
      this.$confirm("确定要删除本记录吗？本操作不可恢复！！！", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/record/del",
          method: "post",
          data: {
            id: scope.row.id,
          },
        }).then((res) => {
          if (res.status == 1) {
            this.getlist();
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/record/add",
        method: "post",
        data: {
          info: this.recordInfo,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.addDiv = false;
        }
      });
    },
    srchRecord() {
      this.page = 1;
      this.getlist();
    },
  },
  created: function () {
    this.getInfo();
    for (let item in this.recordInfo) {
      this.tempInfo[item] = this.recordInfo[item];
    }
  },
};
</script>
  <style>
.toplevel {
  width: 100%;
  font-weight: bold;
}
.toplevel > .el-checkbox__label {
  font-size: 16px;
}
</style>